export const environment = {
  apiEndpoint: 'https://pc-my-gateway-api.nt-test.dev/api',
  recaptchaSiteKey: '6Lf66PcpAAAAAIJcq5_yB_Z3hBJcsST19K7DG5fQ',
  tablePageSizeOption: [5, 10, 20, 50, 100],
  featuresFlag: {
    home: false,
    vouchers: true,
    balanceHistory: true,
    identityVerification: true,
    logout: true,
    deposit: false,
    login: true,
  }
};
